document.addEventListener("DOMContentLoaded", function () {
  var password = document.getElementById("registerPassword");
  var passwordConfirm = document.getElementById("registerPasswordConfirm");

  function checkPassword() {
      password.setCustomValidity("");
      passwordConfirm.setCustomValidity("");

      if (password.value.length < 8) {
          password.setCustomValidity("Le mot de passe doit contenir au moins 8 caractères.");
      }

      if (!/[A-Z]/.test(password.value)) {
          password.setCustomValidity("Le mot de passe doit contenir au moins une lettre majuscule.");
      }

      if (!/\d/.test(password.value)) {
          password.setCustomValidity("Le mot de passe doit contenir au moins un chiffre.");
      }

      if (password.value !== passwordConfirm.value) {
          passwordConfirm.setCustomValidity("Les mots de passe ne correspondent pas.");
      } else {
          passwordConfirm.setCustomValidity("");
      }
  }

  if (password && passwordConfirm) { 
      password.addEventListener("input", checkPassword);
      passwordConfirm.addEventListener("input", checkPassword);
  }
  else{
    
  }
});

$(document).ready(function () {
  $("#myInput").on("keyup", function () {
    var value = $(this).val().toLowerCase();
    var items = $("#myList .mb-5");
    var noResultMessage = $("#noResults");
    
    var found = false;

    items.each(function () {
      var text = $(this).text().toLowerCase();
      
      // Check if the text contains the search value
      if (text.indexOf(value) !== -1) {
        $(this).show();
        found = true;
      } else {
        $(this).hide();
      }
    });

    noResultMessage.toggle(!found);
  });

  const categorySelect = $("#modalSearchCategories");
  const resultsContainer = $("#myList");
  var noResultMessage = $("#noResults");

  categorySelect.on("change", function () {
    const selectedCategory = categorySelect.val();

    resultsContainer.find(".row").each(function () {
      const categoryElement = $(this).find(".mb-5, .d-none");

      if (categoryElement.length === 0) {
        return;
      }

      const category = categoryElement.text().trim();

      if (selectedCategory === "Toutes catégories" || selectedCategory === category) {
        $(this).show();
      } else {
        $(this).hide();
      }
    });

    const visibleItems = resultsContainer.find(".row:visible").length > 0;
    noResultMessage.toggle(!visibleItems);
  });
});

/*const closeButtonElements = document.querySelectorAll('.btn-circle.card-action-end');
closeButtonElements.forEach(function(closeButton) {
  closeButton.addEventListener('click', function() {
    const shouldDelete = confirm('Êtes-vous sûr de vouloir supprimer cet article de votre liste?');
    if (shouldDelete) {
      const col6Element = closeButton.closest('.col-6');
      if (col6Element) {
        col6Element.style.display = 'none';
      }
    }
  });
});

const addButton = document.querySelector('.btn.btn-lg.btn-outline-border');
addButton.addEventListener('click', function() {
  window.location.href = 'account-address-edit.html';
});

$(document).ready(function() {
  $('#contactForm').submit(function(e) {
      e.preventDefault();
      $.ajax({
          type: 'POST',
          url: $(this).attr('action'),
          data: $(this).serialize(),
          success: function(response) {
              $('#statusMessage').html(response);
          }
      });
  });
}); */